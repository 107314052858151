import React from 'react';
import { CssBaseline, Typography } from '@material-ui/core';

class Index extends React.PureComponent {
    render() {
        return (
            <div
                style={{
                    height: '100vh',
                    background: '#3a0000',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CssBaseline />
                <Typography
                    variant="h2"
                    style={{
                        color: '#f2d39e',
                    }}
                >
                    Sito in manutenzione
                </Typography>
            </div>
        );
    }
}

export default Index;
